import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Home } from "./pages/Home";
import { Signup } from "./pages/signup";
import ExclusivePassPage from "./pages/mint/Mint";
import { Web3AuthProvider } from "./authent/contexts/Web3AuthContext";
import { initAmplify } from "./authent/initAmplify";
import { Product } from "./pages/Product/products";
import { Dashboard } from "./pages/dashboard/index";
import ProtectedRoute from "./components/ProtectedRoute/protectedRoute";
import { ToastContainer } from "react-toastify";
import { ClientInvite } from "./pages/clientInvite";
import Signout from "./components/signup/Signout";
import { Legal } from "./pages/legal";
import { Ecosysteme } from "./pages/ecosysteme/ecosysteme";

function App() {
  initAmplify();

  return (
    <Web3AuthProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/ecosysteme" element={<Ecosysteme />} />
          <Route
            path="/products"
            element={
              <ProtectedRoute>
                <Product />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mint"
            element={
              <ProtectedRoute>
                <ExclusivePassPage />
              </ProtectedRoute>
            }
          />
          <Route path="/client" element={<ClientInvite />} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </Web3AuthProvider>
  );
}

export default App;

import CardLogo from "../assets/logo/cardLogo.png";
import CardLogo2 from "../assets/logo/card2Img.png";
import AccordionMenu from "../components/Home/AccordionMenu";
import BgImg from "../assets/background/backgroundHero.png";
import Bg2Img from "../assets/background/bg2Img.png";
import BgPurple from "../assets/background/backgroundOuibild.png";
import BgOppor from "../assets/background/backgroundOpportunity.png";
import BgCard from "../assets/background/backgroundCard.png";
import BgProgress from "../assets/background/backgroundProgress.png";
import BigBgPurple from "../assets/background/bigBgPurple.png";
import logoCardTitle from "../assets/logo/logoCard.png";
import cardBigImg from "../assets/logo/cardBigImg.png";
import coffreImg from "../assets/logo/coffreImg.png";
import logoPatrimonia from "../assets/Home/logoPatrimonia.png";
import courroneImg from "../assets/logo/couronneImg.png";
import cadeauImg from "../assets/logo/cadeauImg.png";
import GreenEclair from "../assets/logo/greenEclair.png";
import graphImg from "../assets/Home/graph.png";
import card2Img from "../assets/Home/card-2.png";
import card3Img from "../assets/Home/card-3.png";
import card4Img from "../assets/Home/card-4.png";
import successImg from "../assets/Home/successCard.png";
import validateBgImg from "../assets/Home/validateImg.png";
import carreBgImg from "../assets/Home/carreBgImg.png";
import HeroImg from "../assets/Home/hero2.png";
import DukanImg from "../assets/Home/dukanImg.png";
import ScrollableCards from "../components/Home/ScrollContainer";
import { Navbar } from "../components/Navbar/navbar";
import { Footer } from "../components/Footer/footer";
import productData from "../config/productData.json";
import { numberWithSpaces } from "../utils/numberSpace";
import { useState } from "react";
import { handleRedirectVehicle } from "../hook/useRedirect";

export const Home = () => {
  const [isPrevenu, setIsPrevenu] = useState(false);

  return (
    <div>
      <Navbar />
      {/** Hero */}
      <section
        className="d-flex align-items-center p-120-hero"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${BgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="mb-r-20 col-12 col-lg-5 d-flex flex-column justify-content-center align-items-start gap-20 mb-lg-0 mx-auto order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
            >
              <div className="card-title d-flex w-auto">
                <img src={logoCardTitle} alt="card-title" />
                <p className="mb-0 bold-600">Une diversité exclusive</p>
              </div>

              <h2 className="tile-hero">
                Convertissez vos actifs en{" "}
                <span className="purple">liquidités instantanées</span>
              </h2>

              <p className="title-20 bold-300 m-0">
                Avec notre solution de tokénisation d’actifs financiers,
                financez vos projets rapidement, simplement et en toute
                sécurité.
              </p>
              <div
                className="d-flex flex-column flex-md-row align-items-md-center align-items-start"
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={400}
              >
                <a
                  href="mailto:contact@ouibild.io"
                  className="button-p-purple title-16 bold d-inline-block w-auto me-md-2 mb-2 mb-md-0"
                  style={{ width: "auto" }}
                >
                  En savoir plus
                </a>
              </div>
            </div>

            <div
              className="col-12 col-lg-7 d-flex flex-column mt-3 mt-lg-0 order-1 order-lg-2"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
              style={{ gap: "10px" }}
            >
              <img src={HeroImg} alt="Placeholder" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      {/** Hero */}

      {/** SECTION REVENU */}
      <section
        className="section-100-0"
        style={{
          backgroundImage: `url(${BgOppor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        id="tokenisation"
      >
        {/* Div pour contenir le contenu avec la classe container */}
        <div className="container">
          <div className="d-flex justify-content-center">
            <div
              className="text-center col-lg-9"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration={700}
              data-aos-delay={400}
            >
              <div
                className="card-title d-inline-flex align-items-center"
                style={{ gap: "5px" }}
              >
                <img src={logoCardTitle} alt="card-title" />
                <p className="mb-0 bold-600">
                  Les avantages de la tokénisation
                </p>
              </div>
              <h2 className="mb-4 title-48 bold">
                <span className="purple">
                  Pourquoi utiliser notre solution de tokénisation ?
                </span>{" "}
                Liquidité, rapidité et encore liquidité.
              </h2>
            </div>
          </div>

          <div
            className="row gx-3 mt-70"
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={500}
            data-aos-easing="ease"
          >
            {[graphImg, card4Img, card2Img, card3Img].map((image, index) => (
              <div key={index} className="col-12 col-lg-3 mb-4">
                <div
                  className="card-revenu p-0 d-flex flex-column"
                  style={{
                    backgroundImage: `url(${BgCard})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "20px",
                    height: "100%",
                  }}
                >
                  <img
                    src={image}
                    className="img-fluid"
                    alt={`Contenu visuel carte ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "250px", // Fixez la hauteur de l'image (ajustez cette valeur selon vos besoins)
                      objectFit: "cover", // Assure que l'image s'adapte correctement à la hauteur définie
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  />
                  <div
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexGrow: 1,
                    }}
                    className="white"
                  >
                    {/* Fixez la hauteur des titres pour garantir un alignement uniforme */}
                    <h2
                      className="title-24 bold mb-2"
                      style={{
                        minHeight: "50px", // Ajustez cette hauteur selon la taille du plus grand titre
                        display: "flex",
                        alignItems: "center", // Centre verticalement le texte à l'intérieur de la hauteur fixée
                      }}
                    >
                      {index === 0 && "Une liquidité instantanée"}
                      {index === 1 &&
                        "Moins de barrières, plus d’investisseurs"}
                      {index === 2 && "Une expertise pluri-disciplinaire"}
                      {index === 3 && "Processus simplifiés et modernes"}
                    </h2>
                    <p
                      className="title-16 bold-400"
                      style={{ opacity: "0.7", marginBottom: "0" }}
                    >
                      {index === 0 &&
                        "Bénéficiez du système de transactions le plus rapide et flexible dans le monde entier, 24/7."}
                      {index === 1 &&
                        "Vos actifs fractionnés en plus petite parts deviennent accessibles à un plus large public."}
                      {index === 2 &&
                        "Notre équipe regroupe les métiers essentiels à la tokénisation : notariat, droit et finance, et ingénierie."}
                      {index === 3 &&
                        "Les processus sont automatisés et sécurisés limitant l’intervention d’intermédiaires."}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/** SECTION REVENU */}

      {/** Comment ca marche */}
      <section
        className="section-120 "
        style={{
          backgroundImage: `url(${BgPurple})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        id="how"
      >
        <div className="container">
          <div className="row">
            <div
              className="mb-r-20 col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center align-items-lg-start gap-30 mb-lg-0 mx-auto"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
            >
              <div
                className="card-title d-flex align-items-center"
                style={{ gap: "5px" }}
              >
                <img src={logoCardTitle} alt="card-title" />
                <p className="mb-0 bold-600">Comment ça marche</p>
              </div>
              <h2 className="text-center text-lg-start title-48 bold white">
                Testez Ouibild en quelques minutes
              </h2>
              <p className="title-18 bold-500 text-center text-lg-start white opacity-08 ">
                Mettez-vous dans la peau de vos futurs investisseurs pour
                comprendre la simplicité du processus !
              </p>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a
                  href="/signup"
                  className="button-rounded-white bold"
                  style={{ width: "auto" }}
                >
                  Je m'inscris !
                </a>
              </div>
            </div>

            <div
              className="col-12 col-lg-7 d-flex flex-column gap-10"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={600}
            >
              <div
                className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between border-r-10 p-20-r-0 p-20-m"
                style={{
                  backgroundColor: "#FFF",
                  overflow: "hidden",
                  height: "190px",
                }}
              >
                <span className="rounded-card">1</span>
                <div className="flex-grow-1 mx-lg-3 text-start">
                  <h3 className="bold title-24">L’inscription </h3>
                  <p className="title-25 bold-400 m-0 opacity-08">
                    Créez votre compte en quelques clics, effectuez votre KYC et
                    accédez à notre plateforme sécurisée.
                  </p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ flexShrink: 0 }}
                ></div>
              </div>

              <div
                className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between border-r-10 p-20-r-0 p-20-m"
                style={{
                  backgroundColor: "#FFF",
                  overflow: "hidden",
                  height: "190px",
                }}
              >
                <span className="rounded-card">2</span>
                <div className="flex-grow-1 mx-lg-3 text-start">
                  <h3 className="bold title-24">Les projets</h3>
                  <p className="title-25 bold-400 m-0 opacity-08">
                    Explorez notre sélection rigoureuse de projets
                    d'investissement et choisissez les meilleurs pour vos
                    clients.
                  </p>
                </div>
              </div>

              <div
                className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between border-r-10 p-20-r-0 p-20-m"
                style={{
                  backgroundColor: "#FFF",
                  overflow: "hidden",
                  height: "190px",
                }}
              >
                <span className="rounded-card">3</span>
                <div className="flex-grow-1 mx-lg-3 text-start">
                  <h3 className="bold title-24">L’affiliation</h3>
                  <p className="title-25 bold-400 m-0 opacity-08">
                    Communiquer à vos investisseurs en partageant simplement une
                    page d’investissement en white label dédiée à votre projet.
                  </p>
                </div>
              </div>

              <div
                className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between border-r-10 p-20-r-0 p-20-m"
                style={{
                  backgroundColor: "#FFF",
                  overflow: "hidden",
                  height: "190px",
                }}
              >
                <span className="rounded-card">4</span>
                <div className="flex-grow-1 mx-lg-3 text-start">
                  <h3 className="bold title-24">Perception des fonds</h3>
                  <p className="title-25 bold-400 m-0 opacity-08">
                    Recevez vos liquidités pour dès lors que l’un de vos
                    investisseurs achètent un ou plusieurs tokens de votre
                    projet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/** Comment ca marche */}

      {/** Accordion Section */}
      <section className="section-120 container" id="avantages">
        <div className="container-one">
          <div className="row g-4 g-lg-5 align-items-center justify-content-between d-flex">
            <div className="col-12 col-md-12 col-lg-6 mb-4 mb-lg-0 order-1 d-flex align-items-stretch">
              <img
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={300}
                src={CardLogo}
                className="img-fluid w-100"
                style={{ objectFit: "cover", display: "block" }}
                alt="card logo"
              />
            </div>

            <div
              className="col-12 col-md-12 col-lg-6 order-2"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
              data-aos-easing="ease"
            >
              <div
                className="card-title d-inline-flex align-items-center"
                style={{ gap: "5px" }}
              >
                <img src={logoCardTitle} alt="card-title" />
                <p className="mb-0 bold-600">Perception des fonds</p>
              </div>

              <div className="mb-5">
                <h2 className="title-2 title-48 bold purple-fonce">
                  Récupérez vos investissements <br />à tout moment
                </h2>
              </div>

              <div className="mb-5">
                <p
                  className="text-2 mb-4 title-18 opacity-08"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-delay={290}
                  data-aos-easing="ease"
                >
                  En tokénisant votre actif, vous allez le fractionner en
                  plusieurs parts nommés token. À chaque Token acheté d’un
                  montant défini vendu vous percevrez vos fonds.
                </p>
              </div>
              <div className="mb-4">
                <AccordionMenu />
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <a
                  href="mailto:contact@ouibild.io"
                  className="button-p-purple d-flex w-auto"
                >
                  Tokéniser mon 1er bien
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/** SECTION REVENU */}
      <section className="section-100-0 container" id="professionnels">
        <div className="d-flex justify-content-center">
          <div
            className="text-center col-lg-10"
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration={700}
            data-aos-delay={400}
          >
            <div
              className="card-title d-inline-flex align-items-center"
              style={{ gap: "5px" }}
            >
              <img src={logoCardTitle} alt="card-title" />
              <p className="mb-0 bold-600">La tokénisation</p>
            </div>
            <h2 className="mb-4 title-48 bold">
              <span className="purple">
                Comment ça marche ? <br />{" "}
              </span>{" "}
              Le processus de tokénisation signée Ouibild
            </h2>
            <p className="mb-4 title-16 bold-400">
              Transformez vos actifs en tokens et offrez à vos investisseurs une
              liquidité rapide et des rendements supérieurs.
            </p>
          </div>
        </div>
        <div
          className="row mt-3 g-3"
          data-aos="fade-up"
          data-aos-duration={700}
          data-aos-delay={500}
          data-aos-easing="ease"
        >
          <div className="col-12 col-md-6 col-lg-4 d-flex align-items-stretch">
            <div
              className="card-shadow w-100 text-white d-flex flex-column"
              style={{
                overflow: "hidden",
              }}
            >
              <div style={{ position: "relative", width: "100%" }}>
                <img
                  src={carreBgImg}
                  alt="Placeholder"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  className="img-fluid border-r-10"
                />
              </div>

              <div
                className="content d-flex flex-column justify-content-center align-items-center text-center"
                style={{
                  flexGrow: 1,
                }}
              >
                <h2 className="title-24 bold black">Audit de faisabilité</h2>
                <p className="title-16 bold-400 grey mt-2">
                  Nous analysons vos actifs pour déterminer leur potentiel de
                  tokenisation : la structure juridique, la rentabilité
                  projetée, les opportunités de marché. et une roadmap.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 d-flex align-items-stretch">
            <div
              className="card-shadow w-100 text-white d-flex flex-column"
              style={{
                overflow: "hidden",
              }}
            >
              <div style={{ position: "relative", width: "100%" }}>
                <img
                  src={validateBgImg}
                  alt="Placeholder"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  className="img-fluid border-r-10"
                />
              </div>

              <div
                className="content d-flex flex-column justify-content-center align-items-center text-center"
                style={{
                  flexGrow: 1,
                }}
              >
                <h2 className="title-24 bold black">
                  Compliance & Développement
                </h2>
                <p className="title-16 bold-400 grey mt-2">
                  Nous développons votre solution en conformité avec les
                  réglementations en vigueur et mettons en place une
                  infrastructure technique sécurisée et transparente.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4 d-flex align-items-stretch">
            <div
              className="card-shadow w-100 text-white d-flex flex-column"
              style={{
                overflow: "hidden",
              }}
            >
              <div style={{ position: "relative", width: "100%" }}>
                <img
                  src={successImg}
                  alt="Placeholder"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  className="img-fluid border-r-10"
                />
              </div>

              <div
                className="content d-flex flex-column justify-content-center align-items-center text-center"
                style={{
                  flexGrow: 1,
                  padding: "0px 20px 30px 20px",
                }}
              >
                <h2 className="title-24 bold black">
                  Lancement de votre collecte
                </h2>
                <p className="title-16 bold-400 grey mt-2">
                  Votre page d’investissement est créée sous votre marque à
                  l’aide de nos équipes techniques et promue avec le support de
                  nos équipes marketing.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center m">
            <a
              href="mailto:contact@ouibild.io"
              className="button-p-purple d-flex w-auto mt-3"
            >
              Prenons rendez-vous
            </a>
          </div>
        </div>
      </section>
      {/** SECTION REVENU */}

      {/** SECTION ATTRACTIFS */}
      <section
        className=" d-flex justify-content-center align-items-center position-relative"
        data-aos="fade-up"
        data-aos-duration={700}
        data-aos-delay={300}
        data-aos-easing="ease"
        style={{
          width: "100%",
          backgroundImage: `url(${BgPurple})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "100px 0 300px 0",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-10 col-10 text-center"
              style={{
                color: "white",
              }}
            >
              <h3 className="mb-3 title-18 bold">Ne ratez pas le coche ! </h3>
              <h2 className="mb-4 title-48 bold">
                Nous rencontrons quelques 8000 professionnels de la gestion de
                patrimoine cette année !
              </h2>
              <div className="d-flex justify-content-center">
                <p
                  className="mb-4 title-16 bold-400 text-center"
                  style={{ opacity: "0.8", maxWidth: "600px" }}
                >
                  Pour le lancement de Ouibild Pro, nous partons à la rencontre
                  des acteurs majeurs de l’investissement : Les CGP pour qui
                  nous avons développer un Dashboard spécifique de gestion
                  mutualisée.
                </p>
              </div>

              <a
                href="/signup"
                className="button-white-carre title-16 bold mb-4"
                style={{
                  width: "auto",
                  marginTop: "20px",
                  display: "inline-block",
                }}
              >
                Profiter de l’opportunité
              </a>
              <img
                src={logoPatrimonia}
                alt="logo patrimonia"
                className="text-center  mx-auto mt-3"
              />
            </div>
          </div>
        </div>
      </section>

      <div
        data-aos="fade-up"
        data-aos-duration={700}
        data-aos-delay={400}
        data-aos-easing="ease"
        className="container d-flex justify-content-center"
        style={{
          position: "relative",
          marginTop: "-250px",
          zIndex: 2,
        }}
      >
        <div
          className="col-12 col-lg-10 bg-light-purple border-r-20 d-flex flex-column flex-lg-row align-items-stretch"
          style={{
            display: "flex",
          }}
        >
          {/* Partie gauche avec l'image en background */}
          <div
            className="col-12 col-lg-3 position-relative height-r-500 border-rl-20 "
            style={{
              backgroundImage: `url(${DukanImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>

          {/* Partie droite (à droite de l'image sur bureau, en dessous sur mobile) */}
          <div
            className="col-12 col-lg-9 p-90-r border-r-20-0 justify-content-center d-flex flex-column"
            style={{
              backgroundColor: "#D3B1E7",
              padding: "30px",
            }}
          >
            <h3 className="mb-3 title-36 bold">
              Parmi les choix de financement présentés, j'ai choisi le plus
              rapide et simple.
            </h3>
            <p className="title-16 bold-400">
              Avec les équipes Ouibild nous avons créé une maison d’édition de
              livres personnalisés, socle technologique de mon projet “Le Livre
              de mon poids”. La tokénisation de ce projet permet à un plus large
              public de participer à son financement. Outre le succès attendu de
              l’opération, cette manière de levée des fonds moderne est
              autrement plus rapide que dans un schéma traditionnel et s’adapte
              parfaitement à des projets de ce type.
            </p>
            <span className="title-60 bold-400 allison-regular purple">
              Pierre Dukan
            </span>
            <p className="title-20 bold purple">FONDATEUR ET CEO DE DUKAN</p>
          </div>
        </div>
      </div>

      {/** SECTION ATTRACTIFS */}

      {/** SECTION PROJETS */}
      <section className="section-100-0 container" id="projets">
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="text-center col-lg-8 col-12"
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={300}
            data-aos-easing="ease"
          >
            <div
              className="card-title d-inline-flex align-items-center"
              style={{ gap: "5px" }}
            >
              <img src={logoCardTitle} alt="card-title" />
              <p className="mb-0 bold-600">La tokénisation</p>
            </div>
            <h2 className="mb-4 title-50 bold">
              Accédez à notre catalogue client{" "}
              <span className="purple">d’actifs déjà tokénisés</span>
            </h2>
            <p className="mb-4 title-18 bold-400 opacity-08">
              Cinéma, projet de construction d’envergure, obligations
              d’entreprise... Tous les secteurs sont concernés (sous réserve de
              l’audit)!
            </p>
          </div>
        </div>

        <div
          className="row g-3 mt-2"
          data-aos="fade-up"
          data-aos-duration={700}
          data-aos-delay={400}
          data-aos-easing="ease"
        >
          {/* Filtre les produits disponibles */}
          {productData
            .filter((product) => product.available) // Filtrer uniquement les produits disponibles
            .map((product, index) => (
              <div key={index} className="col-12 col-md-12 col-lg-6 mx-auto">
                {product.link && (
                  <a
                    onClick={() => handleRedirectVehicle(product.link)}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="card-custom position-relative d-flex flex-column justify-content-between"
                      style={{
                        backgroundImage: `url(${product.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        overflow: "hidden",
                      }}
                      data-aos="fade-up"
                      data-aos-delay={300 + index * 100}
                      data-aos-duration={400}
                    >
                      <div
                        className="text-container p-20-t-30 backdrop-6"
                        style={{ zIndex: 2 }}
                      >
                        <h3 className="title-16 bold-400 text-white">
                          {product.Categorie}
                        </h3>
                        <p className="title-30 bold text-white">
                          {product.title}
                        </p>
                        <p className="title-16 bold-400 text-white">
                          {product.subtitle}
                        </p>
                      </div>

                      <div
                        className="info-section mt-auto"
                        style={{ zIndex: 2 }}
                      >
                        <div className="row gx-3 gy-3">
                          <div
                            className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                            style={{ gap: "15px" }}
                          >
                            <p className="mb-0 title-10 bold-400">Objectif</p>
                            <p className="mb-0 title-16 bold">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                              >
                                <path
                                  d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                  fill="white"
                                />
                              </svg>
                            </p>
                          </div>

                          <div
                            className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                            style={{ gap: "15px" }}
                          >
                            <p className="mb-0 title-10 bold">Invest minimum</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                            >
                              {/* SVG Path */}
                              <path
                                d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div
                            className="col-4 col-lg-4 text-white d-flex flex-column p-0"
                            style={{ gap: "15px" }}
                          >
                            <p className="mb-0 title-10 bold-400">Rendement</p>
                            <p className="mb-0 title-16 bold">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                              >
                                <path
                                  d="M14.916 7.5H15.666C15.8649 7.5 16.0557 7.57902 16.1963 7.71967C16.337 7.86032 16.416 8.05109 16.416 8.25V15.75C16.416 15.9489 16.337 16.1397 16.1963 16.2803C16.0557 16.421 15.8649 16.5 15.666 16.5H3.66602C3.4671 16.5 3.27634 16.421 3.13569 16.2803C2.99503 16.1397 2.91602 15.9489 2.91602 15.75V8.25C2.91602 8.05109 2.99503 7.86032 3.13569 7.71967C3.27634 7.57902 3.4671 7.5 3.66602 7.5H4.41602V6.75C4.41602 6.06056 4.55181 5.37787 4.81565 4.74091C5.07949 4.10395 5.4662 3.5252 5.9537 3.03769C6.44121 2.55018 7.01997 2.16347 7.65693 1.89963C8.29389 1.6358 8.97658 1.5 9.66602 1.5C10.3555 1.5 11.0381 1.6358 11.6751 1.89963C12.3121 2.16347 12.8908 2.55018 13.3783 3.03769C13.8658 3.5252 14.2525 4.10395 14.5164 4.74091C14.7802 5.37787 14.916 6.06056 14.916 6.75V7.5ZM4.41602 9V15H14.916V9H4.41602ZM8.91602 10.5H10.416V13.5H8.91602V10.5ZM13.416 7.5V6.75C13.416 5.75544 13.0209 4.80161 12.3177 4.09835C11.6144 3.39509 10.6606 3 9.66602 3C8.67145 3 7.71763 3.39509 7.01437 4.09835C6.3111 4.80161 5.91602 5.75544 5.91602 6.75V7.5H13.416Z"
                                  fill="white"
                                />
                              </svg>
                            </p>
                          </div>

                          <div className="w-100 mt-3">
                            <div className="progress" style={{ height: "5px" }}>
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{
                                  width: `${product.progress}%`,
                                  height: "5px",
                                }}
                                aria-valuenow={product.progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
              </div>
            ))}
        </div>
      </section>

      {/** SECTION SCROLL CARD */}
      <section
        className="section-100-0 d-flex flex-column justify-content-center align-items-center position-relative"
        data-aos="fade-up"
        data-aos-duration={700}
        data-aos-delay={300}
        data-aos-easing="ease"
      >
        <div className="container text-center text-white mb-5">
          <div className="row ">
            {/* Partie "Une diversité" alignée en start avec width ajustée */}
            <div
              className="card-title d-inline-flex align-items-center mx-auto mx-lg-0"
              style={{
                gap: "5px",
                width: "fit-content",
              }}
            >
              <img src={logoCardTitle} alt="card-title" />
              <p className="mb-0 bold-600 purple">Votre projet</p>
            </div>

            {/* Conteneur pour le h2 et le p */}
            <div className="col-12 d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
              {/* h2 dans un col-4 */}
              <div className="col-md-4">
                <h2 className="title-36 bold mb-2 mb-md-0 me-md-3 text-md-start black">
                  Qu’est-ce que je peux tokéniser ?
                </h2>
              </div>

              {/* p dans un col-6 */}
              <div className="col-md-6">
                <p
                  className="mb-0 title-16 bold-400 text-md-start black"
                  style={{ opacity: "0.8" }}
                >
                  Presque tout enfin tout dépendra de l’audit ;). Rassurez-vous
                  la tokénisation ouvre le champ des possibles autant pour vous
                  et vos investisseurs et ce d’une simple levée de fond à la
                  construction d’un parc immobilier.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <ScrollableCards />
            </div>
          </div>
        </div>
      </section>

      <section
        className="section-100-0"
        style={{
          backgroundImage: `url(${Bg2Img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="mb-r-20 col-10 col-lg-8 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start gap-30 mb-lg-0 mx-auto mx-lg-0">
            <h2 className="title-48 white bold text-center text-lg-start">
              Besoin de plus d’informations sur la tokénisation ?
            </h2>
            <a
              href="mailto:contact@ouibild.io"
              className="button-white-carre title-16 bold border-r-40"
              style={{
                width: "auto",
                marginTop: "20px",
                display: "inline-block",
              }}
            >
              En savoir plus
            </a>
          </div>
        </div>
      </section>

      {/** SECTION AVIS */}
      <section className="section-120 container">
        <div className="container-one">
          <div className="row g-4 g-lg-5 align-items-center justify-content-between">
            <div className="col-12 col-md-12 col-lg-6 mb-4 mb-lg-0 order-1">
              <img
                data-aos="fade-up"
                data-aos-duration={700}
                data-aos-delay={300}
                src={CardLogo2}
                className="img-fluid w-100 h-100"
                style={{ objectFit: "cover", display: "block" }}
                alt="card logo"
              />
            </div>

            <div
              className="col-12 col-md-12 col-lg-6 order-2"
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-delay={300}
              data-aos-easing="ease"
            >
              <div
                className="card-title d-inline-flex align-items-center"
                style={{ gap: "5px" }}
              >
                <img src={logoCardTitle} alt="card-title" />
                <p className="mb-0 bold-600">Votre page d’investissement</p>
              </div>

              <div className="mb-4">
                <h2 className="title-2 title-48 bold purple-fonce">
                  Votre page en White label
                </h2>
              </div>

              <div className="mb-4">
                <p
                  className=" mb-4 title-16 bold-400"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-delay={290}
                  data-aos-easing="ease"
                >
                  Via la solution Ouibild, nous vous développons une page
                  internet propre à votre marque fonctionnelle et sécurisée.
                </p>
              </div>

              <div className="mb-5 d-flex flex-column gap-20">
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <img src={GreenEclair} alt="card-title" />
                  <p className="mb-0 title-18 bold">
                    Visa, Mastercard, Apple Pay, Google Pay, Crypto...
                  </p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <img src={GreenEclair} alt="card-title" />
                  <p className="mb-0 title-18 bold">
                    Génération d’un Titre d’Investissement Numérique (TIN)
                  </p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <img src={GreenEclair} alt="card-title" />
                  <p className="mb-0 bold title-18">
                    Visibilité de la collecter de fonds en temps réel
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-start gap-2">
                <a href="mailto:contact@ouibild.io" className="button-p-purple">
                  Passons à l’audit !
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section-100-0"
        style={{
          backgroundImage: `url(${BigBgPurple})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
        }}
      >
        <div className="text-center">
          <img
            src={cardBigImg}
            alt="dz"
            className="img-fluid"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block", // Assure que l'image n'a pas de marge auto (parfois par défaut en inline)
              margin: "0 auto", // Centre l'image horizontalement dans la div
            }}
          />
        </div>

        <div className="container">
          <div
            className="row"
            data-aos="fade-up"
            data-aos-duration={700}
            data-aos-delay={300}
          >
            <div className="white mb-r-20 col-12 col-lg-12 d-flex flex-column justify-content-center align-items-center  gap-30 mb-lg-0 mx-auto">
              <span className="title-18 bold text-center">
                Notre applicatif pour vous et vos investisseurs
              </span>
              <div className="col-lg-10 d-flex justify-content-center align-items-center text-center">
                <h2 className="title-48 bold">
                  Une solution pensée pour vous mais aussi pour vos
                  investisseurs
                </h2>
              </div>

              <div className="d-flex justify-content-center flex-wrap flex-lg-nowrap">
                <div className="col-lg-4 col-10 flex-column white d-flex text-center">
                  <img src={coffreImg} alt="coffre" className="mb-2" />
                  <h4 className="title-18 bold ">
                    Le Pass Ouibild dès l’inscription
                  </h4>
                  <p className="title-16 bold-400">
                    Les investisseurs obtiennent un pass Ouibild, un sésame
                    personnel et infalsifiable, permettant de se connecter dans
                    tout l’écosystème Ouibild.
                  </p>
                </div>
                <div className="col-lg-4 col-10 flex-column white d-flex text-center">
                  <img src={cadeauImg} alt="coffre" className="mb-2" />
                  <h4 className="title-18 bold ">Un Dashboard personnalisé</h4>
                  <p className="title-16 bold-400">
                    Les investisseurs ou groupe d’investisseurs ont accès à un
                    Dashboard personnalisés où ils peuvent suivre et gérer leur
                    investissement en temps réel.
                  </p>
                </div>
                <div className="col-lg-4 col-10 flex-column white d-flex text-center">
                  <img src={courroneImg} alt="coffre" className="mb-2" />
                  <h4 className="title-18 bold ">Des paiements simplifiés</h4>
                  <p className="title-16 bold-400">
                    Google pay, Apple pay, Visa, Master Card, American express,
                    virement, crypto pour une perception instantanée des fonds
                    investis.
                  </p>
                </div>
              </div>
              <a
                href="mailto:contact@ouibild.io"
                className="button-p-green mt-5"
              >
                Soumettez-nous vos projets
              </a>
            </div>
          </div>
        </div>
      </section>

      {/** 
      <section
        className="section-100-0 d-flex flex-column justify-content-center"
        style={{
          backgroundImage: `url(${BgProgress})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <div
          className="container text-center mb-5"
          data-aos="fade-up"
          data-aos-duration={700}
          data-aos-delay={300}
        >
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 d-flex flex-column  justify-content-between text-center text-md-start">
              <h2 className="title-48 bold text-center">
                Ils nous font confiance{" "}
                <span className="purple">
                  pour révolutionner l’investissement
                </span>
              </h2>

              <p className="mb-0 title-18 bold-500 text-center">
                Découvrez comment nos partenaires et experts s'appuient sur
                Ouibild pour transformer l'accès aux investissements exclusifs
                et offrir des solutions novatrices à leurs clients.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div style={{ overflow: "hidden" }}>
            <Marquee speed={20} gradient={false} style={{ display: "flex" }}>
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="col-8 col-md-4 col-xl-9 container-avis d-flex"
                    style={{ flex: "0 0 auto", marginRight: "20px" }} // Important pour le Marquee
                  >
                    <div className="border-r-20 d-flex flex-column align-items-start card-avis">
                      <img src={imgAvis} alt="Avis" />
                      <h3 className="title-18 bold">Alexandre Dubois</h3>
                      <p className="title-16 bold-400 mb-0">
                        Description rapide de cet élément particulier.
                      </p>
                      <p
                        className="mt-4 title-16"
                        style={{ lineHeight: "1.5", whiteSpace: "normal" }}
                      >
                        Ouibild m'a permis d'offrir à mes clients des
                        opportunités d'investissement uniques et diversifiées,
                        avec une transparence totale.
                      </p>
                    </div>
                  </div>
                ))}
            </Marquee>
          </div>

          <div style={{ overflow: "hidden", marginTop: "20px" }}>
            <Marquee
              speed={20}
              gradient={false}
              style={{ display: "flex" }}
              direction="right"
            >
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="col-4 col-md-6 col-xl-9 container-avis d-flex"
                    style={{ flex: "0 0 auto", marginRight: "20px" }} // Important pour le Marquee
                  >
                    <div className="border-r-20 d-flex flex-column align-items-start card-avis">
                      <img src={imgAvis} alt="Avis" />
                      <h3 className="title-18 bold">Alexandre Dubois</h3>
                      <p className="title-16 bold-400 mb-0">
                        Description rapide de cet élément particulier.
                      </p>
                      <p
                        className="mt-4 title-16"
                        style={{ lineHeight: "1.5", whiteSpace: "normal" }}
                      >
                        Ouibild m'a permis d'offrir à mes clients des
                        opportunités d'investissement uniques et diversifiées,
                        avec une transparence totale.
                      </p>
                    </div>
                  </div>
                ))}
            </Marquee>
          </div>
        </div>
      </section>
      */}
      <Footer />
    </div>
  );
};
